import axios from 'axios';
window.axios = axios;
import _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/auth';
window._ = _;

const token = _.find(document.querySelectorAll('meta'), ['name', 'csrf-token']).content;
axios.defaults.headers['X-CSRF-TOKEN'] = token;
axios.defaults.validateStatus = function (status) {
  // console.log(status);
  // console.log(status >= 200 && status < 300);
  return status >= 200 && status < 300;
};

// @note: https://github.com/YUKASHIKADO/peedock#Googleサインイン
window.onload = () => {
  const { apiKey, authDomain } = (() => {
    if (process.env.RAILS_ENV === 'staging') {
      return { apiKey: 'AIzaSyBVl0xuIdwzKMS_hvk6_vzw_tZSUuNmtoo', authDomain: 'id.stg.vitanote.jp' };
    } else if (process.env.RAILS_ENV === 'production') {
      return { apiKey: 'AIzaSyCfwQSjldj2jejmDUfGr4LEPPuoq8_8g4k', authDomain: 'id.vitanote.jp' };
    }
  })();

  firebase.initializeApp({
    apiKey: apiKey,
    authDomain: authDomain
  });

  const googleSigninButton = document.getElementById('signin_with_google')
  googleSigninButton.onclick = async () => {
    const result = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    const idToken = await result.user.getIdToken()
    const idTokenForm = document.getElementsByTagName('form')[0]
    const idTokenText = document.getElementById('id_token_form')
    idTokenText.value = idToken

    idTokenForm.submit()
  }
}
